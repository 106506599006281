:root {
  --circle-size: 32px;
  --spacing: 22px;
}

.stepper {
  display: flex;
}

.stepper__item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  &:not(:last-child) {
    &:after {
      content: "";
      position: relative;
      top: calc(var(--circle-size) / 2);
      width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
      left: calc(50%);
      height: 2px;
      background-color: #E0E0E0;
      order: -1;
    }
  }
}

.stepper__number {
  font-size: 14px;
  line-height: 32px;
  color: white;
  --size: 3rem;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.38);
  margin: 0 auto 1rem;
  text-align: center;
}

.activeCircle {
  background-color: #EAAF55;
}

.stepper__title {
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  line-height: 16px;
}

.activeTitle {
  color: rgba(0, 0, 0, 0.87)
}

/*** Non-demo CSS ***/

.wrapper {
  max-width: 350px;
  margin: 2rem auto 0;
  padding-bottom: 32px;
}
