.page {
  background-color: #f5f5f5;
  width: 100%;

  form {
    max-width: 520px;
    margin: auto;
    padding: 32px;
    background-color: white;
    margin-bottom: 32px;
  }

  .innerForm {
    padding: 16px 48px;
  }

  @media only screen and (max-width: 425px) {
    .innerForm {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}

.message {
  font-size: 14px;
  margin-bottom: 16px;
}

.errorField {
  font-size: 14px;
  color: lightcoral;
  width: 100%;
}
.subMessage {
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 10px;
}
.fieldWrapper {
  display: flex;
  align-items: flex-end;
  input {
    padding-left: 20px;
  }
}
.number {
  width: 90%;
}
.month {
  width: 120px;
}
.year {
  width: 120px;
}

.unit {
  padding-right: 20px;
  padding-left: 10px;
}

.code {
  margin-top: 20px;
  width: 180px;
}

.btnSubmit {
  max-width: 297px;
  margin-left: 16px;
}
