.button {
  padding: 8px 32px 8px 32px;
  margin: auto;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.valid {
  background-color: #F09123;
  color: white;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

.invalid {
  background-color: gray;
  color: white;
  pointer-events: none;
}

.disabled {
  height: 44px;
  display: flex;
  flex-wrap: wrap;
  background-color: lightgray;
  border-radius: 10px;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.icon {
  color: white;
  margin-left: 10px;
  svg {
    stroke: transparent;
    fill: white;
    width: 20px;
    height: 20px;
    padding-top: 4px;
  }
}
