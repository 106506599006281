.box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  background-color: white;
  border: 1px solid #E0E0E0;
  &:focus-within {
    border: 2px solid #DA7B0D;
  }
}

.inner {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: relative;
  div {
    text-align: left;
  }
}

.root {
  input {
    position: absolute;
    z-index: 1;
    height: calc(100% - 2px);
    width: 100%;
    font-size: 18px;
    border-radius: 10px;
    top: 1px;
    left: 0;
    bottom: 0;
    right: 0;
    border: none;
    outline: none;
    padding: 0 10px;
    background-color: transparent;
    box-sizing: border-box;
    &:disabled {
      color: gray;
      -webkit-text-fill-color: lightgray;
    }
    &:focus + .string {
      color: #DA7B0D;
    }
  }
}

.disabled {
  color: darkgray;
  span {
    color: darkgray;
  }
}

.disabledBox {
  border: 1px solid lightgray;
}

.error {
  border-color: lightcoral;
  span {
    color: lightcoral;
  }
}

.selected {
  top: -6px;
  left: 5px;
  font-size: 12px;
  line-height: 10px;
  height: 1;
  padding: 0 2px;
  background-color: white;
  span {
    background-color: transparent;
  }
  z-index: 2;
}

.string {
  position: absolute;
  color: rgba(0, 0, 0, 0.38);
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
  opacity: 1;
};
::-moz-placeholder {
  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
  opacity: 1,
}; // Firefox 19+
::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
  opacity: 1;
}; // Edge
