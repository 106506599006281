.page {
  background-color: #f5f5f5;
  width: 100%;

  .wrapper {
    max-width: 520px;
    margin: auto;
    padding: 32px;
    background-color: white;
    margin-bottom: 32px;
  }

  .head {
    margin-bottom: 24px;
  }
}

.row {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-width: thin;
  border-bottom: 1px solid #eeeeee;
  padding-top: 10px;
  padding-bottom: 10px;
}

.title {
  width: 110px;
  flex-shrink: 0;
  font-size: 14px;
}

.value {
  display: flex;
  justify-content: flex-end;
  word-break: break-all;
  margin-right: 10px;
}

.btnSubmit {
  margin-top: 24px;
}
