@font-face {
  font-family: 'Abel';
  src: url("./fonts/Abel-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Abel', sans-serif;
  background-color: #f5f5f5;
}

ol, p {
  padding: 0;
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
