.page {
  background-color: #f5f5f5;
  width: 100%;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 560px;
    background-color: white;
    margin: auto;
    padding: 48px;
    border-radius: 4px;

    img {
      margin-bottom: 24px;
    }
  }

  @media only screen and (max-width: 425px) {
    .container {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  .title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .subTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    font-size: 14px;
    line-break: 24px;
    margin-bottom: 32px;
  }

  .buttonSuccess {
    width: 272px;
    height: 40px;
    border-radius: 4px;
    background-color: #F09123;
    color: white;
    text-align: center;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
